import { StickerCategory } from 'pixie/dist/types/src/config/default-stickers';

const stickers = [
    {
        name: 'Activity',
        type: 'png',
        list: [
            'admission tickets',
            'american football',
            'artist palette',
            'badminton racquet',
            'ballet shoes',
            'baseball',
            'basketball',
            'billiards ball',
            'black chess pawn',
            'bow and arrow',
            'bowling ball',
            'boxing glove',
            'clapper board',
            'cricket bat and ball',
            'curling stone',
            'direct hit',
            'drum with drumsticks',
            'field hockey stick and ball',
            'first place medal',
            'fishing pole and fish',
            'flag in hole',
            'flying disc',
            'game die',
            'goal net',
            'guitar',
            'headphone',
            'ice hockey stick and puck',
            'ice skate',
            'jigsaw puzzle piece',
            'joystick',
            'kite',
            'lacrosse stick and ball',
            'martial arts uniorm',
            'microphone',
            'military medal',
            'musical keyboard',
            'musical score',
            'parachute',
            'perorming arts',
            'reminder ribbon',
            'rosette',
            'rugby football',
            'running shirt with sash',
            'saxophone',
            'second place medal',
            'skateboard',
            'ski and ski boot',
            'sled',
            'slot machine',
            'soccer ball',
            'softball',
            'sports medal',
            'table tennis paddle and ball',
            'tennis racquet and ball',
            'third place medal',
            'ticket',
            'trophy',
            'trumpet',
            'video game',
            'violin',
            'volleyball',
        ],
    },
    {
        name: 'AnimalsNature',
        type: 'png',
        list: [
            'allenlea',
            'ant',
            'baby chick',
            'bactriancamelb',
            'badgera',
            'bat',
            'bear face',
            'bird',
            'black sun with rays',
            'blossom',
            'blow fish',
            'boar',
            'bouquet',
            'bug',
            'butterfly',
            'cactus',
            'cat face',
            'cat',
            'cherry blossom',
            'chicken',
            'chipmunk',
            'christmas tree',
            'cloud with lightning',
            'cloud with rain',
            'cloud with snow',
            'cloud with tornadoa',
            'cloud',
            'comet',
            'cow face',
            'cow',
            'crescent moon',
            'cricket',
            'crocodile',
            'dash symbol',
            'deciduous tree',
            'deer',
            'dog face',
            'dog',
            'dolphin',
            'dove of peace',
            'dragon face',
            'dragon',
            'dromedarycamela',
            'droplet',
            'duck',
            'eagle',
            'earoricee',
            'earth globe americas',
            'earth globe asia australia',
            'earth globe europe aricad',
            'elephant',
            'evergreentree',
            'fire',
            'first quarter moon symbol',
            'first quarter moon with face',
            'fish',
            'flamingo',
            'four leaf clover',
            'frog face',
            'full moon symbol',
            'full moon with face',
            'giraffe',
            'glowing star',
            'goat',
            'gorilla',
            'guide dog',
            'hamster face',
            'hatching chick',
            'hedgehog',
            'herb',
            'hibiscusa',
            'high voltage sign',
            'hippopotamus',
            'honey bee',
            'horse face',
            'horse',
            'kangaroo',
            'koala',
            'ladybug',
            'last quarter moon symbol',
            'last quarter moon with face',
            'leaf fluttering in wind',
            'leopard',
            'lion face',
            'lizard',
            'llama',
            'maple leaf',
            'monkey face',
            'monkey',
            'mosquito',
            'mouse face',
            'mouse',
            'new moon symbol',
            'new moon with face',
            'octopus',
            'ogb',
            'orangutan',
            'otter',
            'owl',
            'ox',
            'oxacea',
            'palm tree',
            'panda face',
            'parrot',
            'paw prints',
            'peacock',
            'penguin',
            'pig face',
            'pig nose',
            'pig',
            'pine decoration',
            'poodle',
            'rabbit face',
            'rabbit',
            'raccoon',
            'rainbow',
            'ram',
            'rat',
            'rhinoceros',
            'ringed planet',
            'rontacing baby chick',
            'rooster',
            'rose',
            'rosette',
            'sauropod',
            'scorpion',
            'seedling',
            'service dog',
            'shamrock',
            'shark',
            'sheep',
            'skunk',
            'sloth',
            'snail',
            'snake',
            'snowflake',
            'snowman without snow',
            'snowman',
            'sparkles',
            'spider',
            'spiderweb',
            'spiral shell',
            'splashing sweat symbol',
            'spouting whale',
            'squid',
            'sun behind cloud',
            'sun with face',
            'sunlower',
            'swan',
            'tanabatatreeb',
            'thunder cloud and rain',
            'tiger face',
            'tiger',
            'trex',
            'tropical fish',
            'tulip',
            'turkey',
            'turtle',
            'umbrella with raindrops',
            'unicorn face',
            'waning crescent moon symbol',
            'waning gibbous moon symbol',
            'water wave',
            'waxing crescent moon symbol',
            'waxing gibbous moon symbol',
            'whale',
            'white medium star',
            'white sun behind cloud with rain',
            'white sun behind cloud',
            'white sun with small cloud',
            'wilted flower',
            'wind blowing face',
            'wolf face',
            'zebra',
        ],
    },
    {
        name: 'FaceEmojis',
        type: 'png',
        list: [
            'alien monstere',
            'angry face',
            'anguished face',
            'astonished face',
            'cat face with tears of joy',
            'cat face with wry smile',
            'clown face',
            'conounded face',
            'conused face',
            'crying cat face',
            'crying face',
            'disappointed but relieved face',
            'disappointed face',
            'dizzy face',
            'drooling face',
            'expressionless face',
            'extraterrestrial alien',
            'face savouring delicious food',
            'face screaming in ear',
            'face throwing a kiss',
            'face with cold sweat',
            'face with cowboy hat',
            'face with head bandage',
            'face with inger covering closed lips',
            'face with look of triumph',
            'face with medical mask',
            'face with monocle',
            'face with one eyebrow raised',
            'face with open mouth and cold sweat',
            'face with open mouth vomiting',
            'face with open mouth',
            'face with party horn and party hat',
            'face with pleading eyes',
            'face with rolling eyes',
            'face with stuck out tongue and tightly closed eyes',
            'face with stuck out tongue and winking eye',
            'face with stuck out tongue',
            'face with tears of joy',
            'face with thermometer',
            'face with uneven eyes and wavy mouth',
            'face without mouth',
            'fearul face',
            'ghost',
            'grimfacing face',
            'grinning cat face with smiling eyes',
            'grinning face with one large and one small eyea',
            'grinning face with smiling eyes',
            'grinning face with star eyes',
            'grinning face',
            'hear no evil monkey',
            'hugging face',
            'hushed face',
            'imp',
            'jack o lantern',
            'japanese goblin',
            'japanese ogre',
            'kiss mark',
            'kissing cat face with closed eyes',
            'kissing face with closed eyes',
            'kissing face with smiling eyes',
            'kissing face',
            'loudly crying face',
            'lushed face',
            'lying face',
            'money mouth face',
            'nauseated face',
            'nerd face',
            'neutral face',
            'overheated face',
            'pensive face',
            'persevering face',
            'pile of poo',
            'pouting cat face',
            'pouting face',
            'reezing face',
            'relfieved face',
            'robot face',
            'rolling on the loor laughing',
            'rowning face with open mouth',
            'see no evil monkey',
            'serious face with symbols covering mouth',
            'shocked face with exploding head',
            'skull and crossbones',
            'skull',
            'sleeping face',
            'sleepy face',
            'slightly rowning face',
            'slightly smiling face',
            'smiling cat face with heart shaped eyes',
            'smiling cat face with open mouth',
            'smiling face with halo',
            'smiling face with heart shaped eyes',
            'smiling face with horns',
            'smiling face with open mouth and cold sweat',
            'smiling face with open mouth and smiling eyes',
            'smiling face with open mouth and tightly closed eyes',
            'smiling face with open mouth',
            'smiling face with smiling eyes and handd covering mouth',
            'smiling face with smiling eyes and three hearts',
            'smiling face with smiling eyes',
            'smiling face with sunglassese',
            'smirking face',
            'sneezing face',
            'speak no evil monkey',
            'thinking face',
            'tired face',
            'unamused face',
            'upside down face',
            'weary cat face',
            'weary face',
            'white rowning face',
            'white smiling face',
            'winking face',
            'worried face',
            'yawning face',
            'zipper mouth face',
        ],
    },
    {
        name: 'Female',
        type: 'png',
        list: [
            '_artist',
            '_astronaut',
            '_bald light skin tone',
            '_biking',
            '_bowing deeply',
            '_climbing light skin tone',
            '_construction worker',
            '_curly haired light skin tone',
            '_doing cartwheelf',
            '_elf light skin tone',
            '_facepalming',
            '_factory worker',
            '_fairy light skin tone',
            '_farmer',
            '_firefighter',
            '_gesturing not ok',
            '_gesturing ok',
            '_getting face massag',
            '_getting haircut',
            '_golfing',
            '_guard',
            '_health worker',
            '_in lotus position light skin tone',
            '_in motorized wheelchair',
            '_in steamy room light skin tone',
            '_judge',
            '_juggling',
            '_kneeling',
            '_mage light skin tone',
            '_mechandic',
            '_mountain biking',
            '_office worker',
            '_pilot',
            '_playing handball',
            '_playing water polo ',
            '_police officer',
            '_pouting',
            '_raising hand',
            '_red haired light skin tone',
            '_rowing boat',
            '_rowning ',
            '_running',
            '_scientist',
            '_shrugging',
            '_singer',
            '_sleuth',
            '_standing',
            '_student',
            '_superhero light skin tone',
            '_supervillain light skin tone',
            '_surfing',
            '_swimming',
            '_teacher',
            '_technologist',
            '_tipping hand',
            '_vampire light skin tone',
            '_walking',
            '_wearing turban',
            '_weight lifting',
            '_white haired light skin tone',
            '_with ball',
            '_with probing cane',
            'blonde ',
            'breast feeding',
            'bride with veil',
            'chef',
            'dancer',
            'deaf ',
            'firefighter',
            'girl',
            'manual wheelchair',
            'mer',
            'mother christmas',
            'older ',
            'person with headscar',
            'pregnant ',
            'princess',
            'woman',
        ],
    },
    {
        name: 'Flags',
        type: 'png',
        list: [
            '_Finland',
            '_afghanistan',
            '_aland islands',
            '_albania',
            '_algeria',
            '_american samo',
            '_andorra',
            '_angola',
            '_anguilla',
            '_antarctica',
            '_antigua barbuda',
            '_argentina',
            '_armenia',
            '_aruba',
            '_ascension island',
            '_australia',
            '_austria',
            '_azerbaijan',
            '_bahamas',
            '_bahrain',
            '_bangladesh',
            '_barbados',
            '_belarus',
            '_belgium',
            '_belize',
            '_benin',
            '_bermuda',
            '_bhutan',
            '_bolivia',
            '_bosnia herzegovina',
            '_botswana',
            '_bouvet island',
            '_brazil',
            '_british indian ocean territory',
            '_british virgin islandS',
            '_brunei',
            '_bulgaria',
            '_burkina faso',
            '_burundia',
            '_cambodia',
            '_cameroon',
            '_canada',
            '_canary islands',
            '_cape verde',
            '_caribbean netherlands',
            '_cayman islands',
            '_central arican republic',
            '_ceuta and melillae',
            '_chad',
            '_chile',
            '_chin',
            '_christmas island',
            '_clipperton island',
            '_cocos islands',
            '_colombia',
            '_comoros',
            '_congo brazzaville',
            '_congo kinshas',
            '_cook islands',
            '_costa rica',
            '_cote divoire',
            '_croatia',
            '_cuba',
            '_curacao',
            '_cyprus',
            '_czech republic',
            '_denmark',
            '_diego garcia',
            '_djibouti',
            '_dominica',
            '_dominican republic',
            '_egypt',
            '_el salvador',
            '_england',
            '_equatorial guinea',
            '_eritrea',
            '_estonia',
            '_ethiopia',
            '_european union',
            '_gabon',
            '_gambia',
            '_georgia',
            '_germany',
            '_ghana',
            '_gibraltar',
            '_greece',
            '_greenland',
            '_grenada',
            '_guadeloupe',
            '_guam',
            '_guatemala',
            '_guernsey',
            '_guinea bissau',
            '_guinea',
            '_guyana',
            '_haiti',
            '_heard and mcdonald islands',
            '_honduras',
            '_hong kong',
            '_hungary',
            '_iceland',
            '_ijieb',
            '_india',
            '_indonesia',
            '_iran',
            '_iraq',
            '_ireland',
            '_isle of man',
            '_israel',
            '_italy',
            '_jamaica',
            '_japan',
            '_jersey',
            '_jordan',
            '_kazakhstan',
            '_kenya',
            '_kiribati',
            '_kosovo',
            '_kuwait',
            '_kyrgyzstan',
            '_laos',
            '_latvia',
            '_lebanon',
            '_lesotho',
            '_liberia',
            '_libya',
            '_liechtenstein',
            '_lithuania',
            '_luxembourg',
            '_macau',
            '_macedonia',
            '_madagascar',
            '_malawi',
            '_maldives',
            '_mali',
            '_malta',
            '_marshall islands',
            '_martiniqu',
            '_mauritani',
            '_mauritius',
            '_mayotte',
            '_mexico',
            '_micronesia',
            '_moldova',
            '_monaco',
            '_mongolia',
            '_montenegro',
            '_montserrat',
            '_morocco',
            '_mozambiqua',
            '_myanmar',
            '_namibia',
            '_nauru',
            '_nepal',
            '_netherlands',
            '_new caledoni',
            '_new zealand',
            '_nicaragua',
            '_niger',
            '_nigeria',
            '_niue',
            '_norolk island',
            '_north korea',
            '_northern mariana islands',
            '_norway',
            '_oman',
            '_pakistan',
            '_palau',
            '_palestinian territories',
            '_panama',
            '_papua new guinea',
            '_paraguay',
            '_peru',
            '_philippines',
            '_pitcairn islands',
            '_poland',
            '_portugal',
            '_puerto rico',
            '_qatar',
            '_reunion',
            '_romania',
            '_russia',
            '_rwanda',
            '_samoa',
            '_san marino',
            '_sao tome principe',
            '_saudi arabia',
            '_scotland',
            '_senegal',
            '_serbia',
            '_seychelles',
            '_sierra leone',
            '_singapore',
            '_sint maarten',
            '_slovakia',
            '_slovenia',
            '_solomon islands',
            '_somalia',
            '_south africa',
            '_south georgia and south sandwich islands',
            '_south korea',
            '_south sudan',
            '_spain',
            '_sri lank',
            '_st barthelemy',
            '_st helen',
            '_st kitts and nevis',
            '_st lucia',
            '_st martin',
            '_st pierre and miquelon',
            '_st vincent and grenadines',
            '_sudan',
            '_suriname',
            '_svalbard and jan mayen',
            '_swaziland',
            '_sweden',
            '_switzerland',
            '_syria',
            '_taiwan',
            '_tajikistan',
            '_tanzania',
            '_thailand',
            '_timor leste',
            '_togo',
            '_tokelau',
            '_tonga',
            '_trinidad and tobago',
            '_tristan da cunha',
            '_tunisia',
            '_turkey',
            '_turkmenistan',
            '_turks and caicos islands',
            '_tuvalu',
            '_uganda',
            '_ukraine',
            '_united arab emirates',
            '_united kingdom',
            '_united nations',
            '_united states',
            '_uruguay',
            '_us outlying islands',
            '_us virgin islands',
            '_uzbekistan',
            '_vanuatu',
            '_vatican city',
            '_venezuela',
            '_vietnam',
            '_wales',
            '_wallis and futuna',
            '_western sahara',
            '_yemen',
            '_zambia',
            '_zimbabwe',
            'chequered',
            'crossed',
            'falkland islands',
            'faroe islands',
            'france',
            'french guian',
            'french polynesia',
            'french southern territories',
            'malaysia',
            'pirate',
            'rainbow',
            'triangular',
            'waving black',
            'waving white',
        ],
    },
    {
        name: 'FoodDrink',
        type: 'png',
        list: [
            'avocado',
            'baby bottle',
            'bacon',
            'bagel',
            'baguette bread',
            'beer mug',
            'bento box',
            'beverage box',
            'birthday cake',
            'bottle with popping cork',
            'bowl with spoon',
            'bread',
            'broccoli',
            'burrito',
            'butter',
            'candy',
            'canned food',
            'carrot',
            'cheese wedge',
            'cherries',
            'chestnut',
            'chocolate bar',
            'chopsticks',
            'clinking beer mugs',
            'clinking glasses',
            'cocktail glass',
            'coconut',
            'cooked rice',
            'cookie',
            'cooking',
            'crab',
            'croissant',
            'cucumber',
            'cup with straw',
            'cupcake',
            'curry and rice',
            'custard',
            'cut of meat',
            'dango',
            'doughnut',
            'dumpling',
            'ear of maiz',
            'egg',
            'eggplant',
            'falafel',
            'fish cake with swirl design',
            'fork and knife with plate',
            'fork and knife',
            'fortune cookie',
            'french fries',
            'fried shrimp',
            'garlic',
            'glass of milk',
            'grapes',
            'green salad',
            'hamburger',
            'honey pot',
            'hot beverage',
            'hot dog',
            'hot pepper',
            'ice cream',
            'ice cube',
            'kitchen knife',
            'kiwi fruit',
            'leafy green',
            'lemon',
            'lobster',
            'lollipop',
            'mate drink',
            'meat on bone',
            'melon',
            'moon cake',
            'oden',
            'onion',
            'oyster',
            'pancakes',
            'peanuts',
            'pie',
            'popcorn',
            'pot of food',
            'potato',
            'poultry leg',
            'pretzel',
            'rice ball',
            'rice cracker',
            'roasted sweet potato',
            'sake bottle and cup',
            'salt shaker',
            'sandwich',
            'shallow pan of food',
            'shaved ice',
            'shortcake',
            'shrimp',
            'slice of pizza',
            'soft ice cream',
            'spaghetti',
            'spoon',
            'steaming bowl',
            'strawberry',
            'stuffed flatbread',
            'sushi',
            'taco',
            'takeout box',
            'tangerine',
            'teacup without handle',
            'tomato',
            'tropical drink',
            'tumbler glass',
            'waffle',
            'watermelon',
            'wine glass',
        ],
    },
    {
        name: 'HandsReg',
        type: 'png',
        list: [
            'call me hand',
            'clapping hands sign',
            'couple with heart',
            'ear with hearing aid',
            'ear',
            'fisted hand sign',
            'flexed biceps',
            'foot',
            'hand with index and middle fingers crossed',
            'handshake',
            'i love you hand sign',
            'kiss',
            'left facing fist',
            'leg',
            'nail polish',
            'nos',
            'ok hand sign',
            'open hands sign',
            'palms up together',
            'person raising both hands in celebration',
            'person with folded hands',
            'pinching hand',
            'raised back of hand',
            'raised fist',
            'raised hand with fingers splay',
            'raised hand with part between middle and ring fingers',
            'reversed hand with middle finger extend',
            'right facing fist',
            'selife',
            'sign of the horns',
            'thumbs down sign',
            'thumbs up sign',
            'victory hand',
            'waving hand sign',
            'white down pointing backhand index',
            'white let pointing backhand index',
            'white right pointing backhand index',
            'white up pointing backhand index',
            'writing hand',
        ],
    },
    {
        name: 'HandsWhite',
        type: 'png',
        list: [
            'call me hand',
            'clapping hands sign',
            'ear with hearing aid',
            'ear',
            'fisted hand sign',
            'flexed biceps',
            'foot',
            'hand with index and middle fingers cross',
            'i love you hand sign',
            'left facing fist',
            'leg',
            'nail polish',
            'nose',
            'ok hand sign',
            'open hands sign',
            'palms up together',
            'person raising both hands in celebration',
            'person with folded hands',
            'pinching hand',
            'raised back of hand',
            'raised fist',
            'raised hand with fingers splay',
            'raised hand with part between middle and ring fingers',
            'reversed hand with middle finger exten',
            'right facing fist',
            'selfie',
            'sign of the horns',
            'thumbs down sign',
            'thumbs up sign',
            'victory hand',
            'waving hand sign',
            'white down pointing backhand index',
            'white let pointing backhand index',
            'white right pointing backhand index',
            'white up pointing backhand index',
            'writing hand',
        ],
    },
    {
        name: 'Male',
        type: 'png',
        list: [
            '_artist',
            '_astronaut',
            '_bald light skin tone',
            '_biking',
            '_bowing deeply',
            '_climbing light skin tone',
            '_construction worker',
            '_cook',
            '_curly haired light skin tone',
            '_dancing',
            '_doing cartwheel',
            '_elf light skin tone',
            '_facepalming',
            '_factory worker',
            '_fairy light skin tone',
            '_farmer',
            '_firefighter',
            '_gesturing not ok',
            '_gesturing ok',
            '_getting face massage',
            '_getting haircut',
            '_golfing',
            '_guard',
            '_health worker',
            '_in business suit levitating',
            '_in lotus position light skin tone',
            '_in motorized wheelchair',
            '_in steamy room light skin tone',
            '_in tuxedo',
            '_in ual wheelchair',
            '_judge',
            '_juggling',
            '_kneeling',
            '_mage light skin tone',
            '_mechandic',
            '_mountain biking',
            '_office worker',
            '_pilot',
            '_playing handball',
            '_playing water polo ',
            '_police officer',
            '_pouting',
            '_raising hand',
            '_red haired light skin tone',
            '_rowing boat',
            '_running',
            '_scientfist',
            '_shrugging',
            '_singer',
            '_sleuth',
            '_standing',
            '_student',
            '_superhero light skin tone',
            '_supervillain light skin tone',
            '_surfing',
            '_swimming',
            '_teacher',
            '_technologist',
            '_tipping hand',
            '_vampire light skin tone',
            '_walking',
            '_wearing turban',
            '_weight lifting',
            '_white haired light skin tone',
            '_with ball',
            '_with gua pi mao',
            '_with probing cane',
            'bearded person',
            'blond ',
            'boy',
            'deaf ',
            'father christmas',
            'frowning',
            'man',
            'merman',
            'older ',
            'prince',
        ],
    },
    {
        name: 'Objects',
        type: 'png',
        list: [
            'abacus',
            'adhesive bandage',
            'alarm clock',
            'alembic',
            'amphora',
            'auto rickshaw',
            'axe',
            'balloon',
            'ballot box with ballot',
            'banjo',
            'banknote with dollar sign',
            'banknote with euro sign',
            'banknote with pound sign',
            'banknote with yen sign',
            'bar chart',
            'bar of soap',
            'basket',
            'bathtub',
            'battery',
            'bellhop bell',
            'black nib',
            'black scissors',
            'black telephone',
            'blue book',
            'bomb',
            'bookmark tabs',
            'bookmark',
            'books',
            'broom',
            'calendar',
            'camera with flash',
            'camera',
            'candle',
            'card file box',
            'card index dividers',
            'card index',
            'carp streamer',
            'chains',
            'chair',
            'chart with downwards trend',
            'chart with upwards trend',
            'clipboard',
            'closed book',
            'closed lock with key',
            'closed mailbox with lowered flag',
            'closed mailbox with raised flage',
            'coin',
            'compression',
            'computer disk',
            'confetti ball',
            'control knobs',
            'couch and lamp',
            'credit card',
            'crossed swords',
            'crystal ball',
            'dagger knife',
            'desktop computer',
            'diving mask',
            'diya lamp',
            'dna double helix',
            'door',
            'drop of blood',
            'dvd',
            'e mail symbol',
            'electric light bulb',
            'electric plug',
            'electric torch',
            'envelope with downwards arrow above',
            'envelope',
            'fax machine',
            'file cabinet',
            'file folder',
            'film frames',
            'film projector',
            'fire extinguisher',
            'firecracker',
            'floppy disk',
            'gear',
            'gem stone',
            'green book',
            'hammer and pick',
            'hammer and wrench',
            'hammer',
            'hole',
            'hourglass with lowing san',
            'hourglass',
            'inbox tray',
            'incoming envelope',
            'izakaya lanterne',
            'japanese dolls',
            'key',
            'keyboard',
            'label',
            'ledger',
            'left pointing magniying glass',
            'level slider',
            'link symbol',
            'linked paperclips',
            'lock with ink pen',
            'lock',
            'lotion bottle',
            'love letter',
            'lower left ballpoint pen',
            'lower left crayon',
            'lower left fountain pen',
            'lower left paintbrush',
            'magnet',
            'mantelpiece clock',
            'manual wheelchair',
            'memo',
            'microscope',
            'mobile phone with rightwards arrow at left',
            'mobile phone',
            'money bag',
            'money with wings',
            'motorized wheelchair',
            'movie camera',
            'nazar amulet',
            'newspaper',
            'notebook with decorative cover',
            'notebook',
            'nut and bolt',
            'old key',
            'open book',
            'open file folder',
            'open lock',
            'open mailbox with lowered flag',
            'open mailbox with raised flag',
            'optical disc',
            'orange book',
            'outbox tray',
            'package',
            'page facing up',
            'page with curl',
            'pager',
            'paperclip',
            'party popper',
            'pencil',
            'personal computer',
            'petri dish',
            'pick',
            'pill',
            'pistol',
            'postal horn',
            'postbox',
            'potable water symbol',
            'prayer beads',
            'printer',
            'probing cane',
            'pushpin',
            'radio',
            'rame with picture',
            'razor',
            'receipt',
            'right pointing magniying glass',
            'roll of paper',
            'rolled up newspaper',
            'round pushpin',
            'safety pin',
            'satellite antenna',
            'scales',
            'scroll',
            'shield',
            'shopping bags',
            'shopping trolley',
            'shower',
            'smoking symbol',
            'spiral calendar pad',
            'spiral note pad',
            'sponge',
            'stethoscope',
            'stopwatch',
            'straight ruler',
            'studio microphone',
            'syring',
            'tear of calendar',
            'tedy bear',
            'telephone receiverd',
            'telescope',
            'television',
            'test tube',
            'thermometer',
            'three button mouse',
            'timer clock',
            'toilet',
            'toolbox',
            'trackball',
            'triangular ruler',
            'uneral urn',
            'video camera',
            'video cassette',
            'waste basket',
            'watch',
            'wind chime',
            'wrapped present',
            'wrench',
            'yo yoa',
        ],
    },
    {
        name: 'People',
        type: 'png',
        list: [
            '_women holding hands light skin tone',
            'family man boy boy',
            'family man boy',
            'family man girl boy',
            'family man girl girl',
            'family man girl',
            'family man man boy boy',
            'family man man boy',
            'family man man girl boy',
            'family man man girl girl',
            'family man man girl',
            'family man woman boy boy',
            'family man woman girl boy',
            'family man woman girl girl',
            'family man woman girl',
            'family woman boy boy',
            'family woman boy',
            'family woman girl boy',
            'family woman girl girl',
            'family woman girl',
            'family woman woman boy boy',
            'family woman woman boy',
            'family woman woman girl boy',
            'family woman woman girl girl',
            'family woman woman girl',
            'family',
            'gender neutral people holding hands',
            'man and woman holding hands  medium light skin tone',
            'man and woman holding hands light skin tone',
            'man and woman holding hands',
            'two men holding hands',
            'two women holding hands',
        ],
    },
    {
        name: 'PeopleObjects',
        type: 'png',
        list: [
            'athletic shoe',
            'ball of yarn',
            'bikini',
            'billed cape',
            'bone',
            'brain',
            'brief case',
            'briefs',
            'bust in silhouette',
            'busts in silhouette',
            'closed umbrella',
            'coat',
            'crown',
            'dark sunglasses',
            'dress',
            'eye',
            'eyeglasses',
            'eyes',
            'fencer',
            'footprints',
            'genie',
            'gloves',
            'goggles',
            'graduation cap',
            'handbag',
            'helmet with white crossd',
            'high heeled shoe',
            'hiking boot',
            'jeans',
            'kimono',
            'kiss mark',
            'lab coat',
            'lat shoe',
            'lipstick',
            'man geniede ',
            'man zombie',
            'mans shoe',
            'mechanical arm',
            'mechanical leg',
            'mouth',
            'necktie',
            'one piece swimsuit',
            'pouch',
            'purse',
            'ribbon',
            'ring',
            'safety vest',
            'sari',
            'scar',
            'school satchel',
            'shorts',
            'skier',
            'sleeping accommodation',
            'snowboarder',
            'socks',
            'speaking head in silhouettee',
            'spool of thread',
            't shirt',
            'tongue',
            'tooth',
            'top hat',
            'woman geniede ',
            'woman zombie',
            'womans boots',
            'womans clothes',
            'womans hat',
            'womans sandal',
            'zombie',
        ],
    },
    {
        name: 'Symbols',
        type: 'png',
        list: [
            'anger symbol',
            'antenna with bars',
            'anticlockwise downwards and upwards open circle arrows',
            'aquarius',
            'aries',
            'arrow pointing rightwards then curving downwards',
            'arrow pointing rightwards then curving upwards',
            'atom symbol',
            'automated teller machine',
            'baby symbol',
            'back with leftwards arrow above',
            'baggage claim',
            'ballot box with check',
            'beating heart',
            'bell with cancellation stroke',
            'bell',
            'biohazard sign',
            'black circle or record',
            'black club suit',
            'black diamond suit',
            'black down pointing double triangle',
            'black heart suit',
            'black heart',
            'black large square',
            'black left pointing double triangle with vertical bare',
            'black left pointing double triangle',
            'black left pointing triangle',
            'black medium small square',
            'black medium square',
            'black question mark ornament',
            'black right pointing double triangle with vertical bare',
            'black right pointing double triangle',
            'black right pointing triangle with double vertical bare',
            'black right pointing triangle',
            'black rightwards arrow',
            'black small square',
            'black spade suit',
            'black square button ',
            'black square button',
            'black square or stop',
            'black universal recycling symbol',
            'black up pointing double triangle',
            'blue heart',
            'broken heart',
            'brown heart',
            'cancer',
            'capricorn',
            'chart with upwards trend and yen sign',
            'cheering megaphone',
            'children crossing',
            'cinema',
            'circled latin capital letter m',
            'clock face eight oclock',
            'clock face eight thirty',
            'clock face eleven oclock',
            'clock face eleven thirty',
            'clock face five oclock',
            'clock face five thirty',
            'clock face four oclock',
            'clock face four thirty',
            'clock face nine oclock',
            'clock face nine thirty',
            'clock face one oclock',
            'clock face one thirty',
            'clock face seven oclock',
            'clock face seven thirty',
            'clock face six oclock',
            'clock face six thirty',
            'clock face ten oclock',
            'clock face ten thirty',
            'clock face three oclock',
            'clock face three thirty',
            'clock face twelfve oclock',
            'clock face twelfve thirty',
            'clock face two oclock',
            'clock face two thirty',
            'clockwise downwards and upwards open circle arrows',
            'clockwise rightwards and leftwards open circle arrows with circled one overlay',
            'clockwise rightwards and leftwards open circle arrows',
            'copyright sign',
            'cross mark',
            'curly loop',
            'currency exchange',
            'customs',
            'diamond shape with a dot inside',
            'do not litter symbol',
            'double curly loop',
            'double exclamation mark',
            'double vertical bar',
            'down pointing red triangle',
            'down pointing small red triangle',
            'downwards black arrow',
            'eight pointed black star',
            'eight spoked asterisk',
            'eject symbol',
            'end with letwards arrow above',
            'exclamation question mark',
            'eye in speech bubble',
            'fleur de lis',
            'gemini',
            'globe with meridians',
            'green heart',
            'growing heart',
            'heart decoration',
            'heart with arrow',
            'heart with ribbon',
            'heavy black heart',
            'heavy check mark',
            'heavy division sign',
            'heavy dollar sign',
            'heavy exclamation mark symbol',
            'heavy heart exclamation mark ornament',
            'heavy large circleb',
            'heavy minus sign',
            'heavy multiplication x',
            'heavy plus sign',
            'high brightness symbol',
            'hundred points symbol',
            'infinity sign',
            'inormation source',
            'input symbol or latin capital letters',
            'input symbol or latin letters',
            'input symbol or latin small letters',
            'input symbol or numbers',
            'japanese acceptable',
            'japanese application',
            'japanese baragin',
            'japanese congratulations',
            'japanese discount',
            'japanese free of charge',
            'japanese here',
            'japanese monthly amount',
            'japanese no vacancy',
            'japanese not free of charge',
            'japanese open for business',
            'japanese passing grade',
            'japanese prohibited',
            'japanese reserved',
            'japanese secert',
            'japanese service charge',
            'japanese symbol or beginner',
            'japanese vacancy',
            'keycap asterisk',
            'keycap digit eight',
            'keycap digit five',
            'keycap digit four',
            'keycap digit nine',
            'keycap digit one',
            'keycap digit seven',
            'keycap digit six',
            'keycap digit three',
            'keycap digit two',
            'keycap digit zero',
            'keycap number sign',
            'keycap ten',
            'large blue circle',
            'large blue diamond',
            'large blue square',
            'large brown circle',
            'large brown square',
            'large green circle',
            'large green square',
            'large orange circle',
            'large orange diamond',
            'large orange square',
            'large purple circle',
            'large purple square',
            'large red circle',
            'large red square',
            'large yellow circle',
            'large yellow square',
            'latin cross',
            'left luggage',
            'left right arrow',
            'left speech bubble',
            'leftwards arrow with hook',
            'leftwards black arrow',
            'leo',
            'libra',
            'low brightness symbol',
            'lower playing cards',
            'mahjong tile red dragon',
            'medium black circle',
            'medium white circle',
            'menorah with nine branches',
            'mens symbol',
            'mobile phone',
            'multiple musical notes',
            'musical note',
            'name badge',
            'negative squared ab',
            'negative squared cross marke',
            'negative squared latin capital letter a',
            'negative squared latin capital letter b',
            'negative squared latin capital letter o',
            'negative squared latin capital letter p',
            'no bicycles',
            'no entry sign',
            'no entry',
            'no mobile phones',
            'no one under eighteen symbol',
            'no pedestrians',
            'no smoking symbol',
            'non potable water symbol',
            'north east arrow',
            'north west arrow',
            'octagonal sign',
            'om symbol',
            'on with exclamation mark with left right arrow above',
            'ophiuchus',
            'orange heart',
            'part alternation mark',
            'passport control',
            'peace symbol',
            'pisces',
            'place of worship',
            'playing card black joker',
            'public address loudspeaker',
            'purple heart',
            'put litter in its place symbol',
            'radioactive sign',
            'registered sign',
            'restroom',
            'revolving hearts',
            'right anger bubble',
            'rightwards arrow with hook',
            'sagittarius',
            'scorpius',
            'six pointed star with middle dot',
            'sleeping symbol',
            'small blue diamond',
            'small orange diamond',
            'soon with rightwards arrow above',
            'south east arrow',
            'south west arrow',
            'sparkle',
            'sparkling heart',
            'speaker with cancellation stroke',
            'speaker with one sound wave',
            'speaker with three sound waves',
            'speaker',
            'squared cl',
            'squared cool',
            'squared free',
            'squared id',
            'squared new',
            'squared ng',
            'squared ok',
            'squared sos',
            'squared up with exclamation mark',
            'squared vs',
            'star and crescent',
            'star of david',
            'taurus',
            'thought balloon',
            'top with upwards arrow above',
            'trade mark sign',
            'trident emblem',
            'twisted rightwards arrows',
            'two hearts',
            'up down arrow',
            'up pointing red triangle',
            'up pointing small red triangle',
            'upwards black arrow',
            'vibration mode',
            'virgo',
            'warning sign',
            'water closet',
            'wavy dash',
            'wheel o dharma',
            'wheelchair symbol',
            'white exclamation mark ornament',
            'white heart',
            'white heavy check mark',
            'white large square',
            'white lowerae',
            'white medium small squared',
            'white medium squareb',
            'white question mark ornament',
            'white small squareab',
            'white square button',
            'womens symbol',
            'yellow heart',
            'yin yang ',
            'yin yang',
        ],
    },
    {
        name: 'TravelPlaces',
        type: 'png',
        list: [
            'aerial tramway',
            'airplane arriving',
            'airplane departure',
            'airplane',
            'ambulance',
            'anchor',
            'articulated lorry',
            'automobile',
            'bank',
            'beach with umbrella',
            'bicycle',
            'brick',
            'bridge at night',
            'building construction',
            'bus stop',
            'bus',
            'camping',
            'canoe',
            'church',
            'circus tent',
            'cityscape at dusk',
            'cityscaped',
            'classical building',
            'compass',
            'construction sign',
            'convenience store',
            'delivery truck',
            'department store',
            'derelict house building',
            'desert island',
            'desert',
            'european castle',
            'european post office',
            'factory',
            'ferris wheel',
            'ferry',
            'fire engine',
            'firework sparkler',
            'fireworks',
            'foggy',
            'fountain',
            'fuel pumpd',
            'helicopter',
            'high speed train with bullet nose',
            'high speed train',
            'hindu temple',
            'horizontal traic light',
            'hospital',
            'hotel',
            'house building',
            'house buildings',
            'house with gardene',
            'japanese castle',
            'japanese post office',
            'kaaba',
            'light rail',
            'love hotel',
            'luggage',
            'lying saucer',
            'metro',
            'milky way',
            'minibus',
            'moai',
            'monoraild',
            'moon viewing ceremony',
            'mosque',
            'motor boat',
            'motor scooter',
            'motorway',
            'mount fuji',
            'mountain cableway',
            'mountain railway',
            'mountain',
            'national park',
            'night with stars',
            'office building',
            'oil drum',
            'oncoming automobile',
            'oncoming bus',
            'oncoming police car',
            'oncoming taxi',
            'passenger ship',
            'police car',
            'police cars revolving light',
            'racing car',
            'racing motorcycle',
            'railway car',
            'railway track',
            'recreational vehicle',
            'rocket',
            'roller coaster',
            'sailboat',
            'satellite',
            'school',
            'scooter',
            'seat',
            'shinto shrine',
            'ship',
            'shooting star',
            'silhouette of japan',
            'small airplane',
            'snow capped mountain',
            'speedboat',
            'stadium',
            'station',
            'statue of liberty',
            'steam locomotive',
            'sunrise over mountains',
            'sunrise',
            'sunset over buildings',
            'suspension railway',
            'synagogue',
            'taxi',
            'tent',
            'tokyo tower',
            'tractor',
            'train',
            'tram car',
            'tram',
            'trolleybus',
            'vertical traic light',
            'volcano',
            'wedding',
            'world map',
        ],
    },
] as StickerCategory[];

export { stickers };
