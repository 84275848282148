import axios, { AxiosInstance } from 'axios';

enum Endpoints {
    Ipfs = '/pump/ipfs',
}

export interface UploadMetadataArgs {
    file: File;
    name: string;
    symbol: string;
    description: string;
    twitter: string;
    telegram: string;
    website: string;
    showName: string;
}

export interface UploadMetadataResponse {
    metadata: {
        name: string;
        symbol: string;
        description: string;
        image: string;
        showName: boolean;
        createdOn: string;
        twitter: string;
        telegram: string;
        website: string;
    };
    metadataUri: string;
}

class PumpFunApi {
    private readonly axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_URL,
            //withCredentials: true,
            headers: { 'x-api-key': process.env.REACT_APP_BACKEND_APIKEY },
        });
        this.axios.interceptors.response.use((res) => res.data);
    }

    public uploadMetadata(args: UploadMetadataArgs) {
        const data = new FormData();
        Object.entries(args).forEach(([key, value]) => data.append(key, value));
        return this.axios.post<UploadMetadataResponse, UploadMetadataResponse, FormData>(Endpoints.Ipfs, data);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PumpFunApi();
