import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const MTProto = require('@mtproto/core/envs/browser');

const apiId = Number(process.env.REACT_APP_TELEGRAM_API_ID);
const apiHash = process.env.REACT_APP_TELEGRAM_API_HASH as string;

const mtproto = new MTProto({
    api_id: apiId,
    api_hash: apiHash,
});

interface Message {
    chatTitle: string;
    message: string;
    url: string;
    date: number;
}

interface MTProtoError {
    error_message?: string;
}

export const useTelegram = () => {
    const [authorized, setAuthorized] = useState<boolean>(false);

    useEffect(() => {
        // TODO: implement better authorized checker
        const intervalId = setInterval(() => {
            mtproto
                .call('users.getFullUser', {
                    id: {
                        _: 'inputUserSelf',
                    },
                })
                .then(() => setAuthorized(true))
                .catch((error: MTProtoError) => {
                    if (!error?.error_message?.includes('FLOOD')) {
                        //console.error(error);
                        setAuthorized(false);
                    }
                });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const sendCode = (phoneNumber: string): Promise<string> =>
        mtproto
            .call('auth.sendCode', {
                phone_number: phoneNumber,
                api_id: apiId,
                api_hash: apiHash,
                settings: {
                    _: 'codeSettings',
                },
            })
            .then((response: any) => response.phone_code_hash)
            .catch((error: MTProtoError) => {
                console.error(error);
                toast.error('Error: Invalid Number');
                throw error;
            });

    const signIn = (phoneNumber: string, phoneCodeHash: string, code: string): Promise<void> =>
        mtproto
            .call('auth.signIn', {
                phone_number: phoneNumber,
                phone_code_hash: phoneCodeHash,
                phone_code: code,
            })
            .then((response: any) => {
                setAuthorized(response._ === 'auth.authorization');
                if (response._ === 'auth.authorization') {
                    toast.success('Login Successfully!');
                }
                if (response._ === 'auth.authorizationSignUpRequired') {
                    console.error("An account with this phone number doesn't exist on Telegram: the user has to enter basic information and sign up");
                    toast.error("An account with this phone number doesn't exist on Telegram: the user has to enter basic information and sign up");
                }
            })
            .catch((error: MTProtoError) => console.error(error));

    const getMessages = (username: string, limit: number): Promise<Message[]> =>
        mtproto
            .call('contacts.resolveUsername', {
                username,
            })
            .then((response: any) =>
                mtproto
                    .call('messages.getHistory', {
                        peer: {
                            _: 'inputPeerChannel',
                            channel_id: response.peer.channel_id,
                            access_hash: response.chats[0].access_hash,
                        },
                        limit,
                    })
                    .then((response: any) =>
                        response.messages.map((message: any) => ({
                            chatTitle: response.chats[0].title,
                            message: message.message,
                            url: message?.reply_markup?.rows[0]?.buttons[0]?.url ?? `https://t.me/${username}/${message.id}`,
                            date: message.date,
                        })),
                    ),
            );

    const createChannel = (title: string, about: string): Promise<void> =>
        mtproto.call('channels.createChannel', {
            title,
            about,
        });

    const setAdmin = (channelId: number, channelAccessHash: string, userId: number, userAccessHash: string): Promise<void> =>
        mtproto.call('channels.editAdmin', {
            channel: {
                _: 'inputChannel',
                channel_id: channelId,
                access_hash: channelAccessHash,
            },
            user_id: {
                _: 'inputUser',
                user_id: userId,
                access_hash: userAccessHash,
            },
            admin_rights: {
                _: 'chatAdminRights',
                change_info: true,
                post_messages: true,
                edit_messages: true,
                delete_messages: true,
                ban_users: true,
                invite_users: true,
                pin_messages: true,
                add_admins: true,
                anonymous: true,
                manage_call: true,
                other: true,
                manage_topics: true,
            },
            rank: 'Admin',
        });

    const checkUsername = (username: string): Promise<boolean> =>
        mtproto.call('account.checkUsername', {
            username,
        });

    const resolveUsername = (username: string): Promise<boolean> =>
        mtproto.call('contacts.resolveUsername', {
            username,
        });

    const updateUsername = (channelId: number, accessHash: string, username: string): Promise<boolean> =>
        mtproto.call('channels.updateUsername', {
            channel: {
                _: 'inputChannel',
                channel_id: channelId,
                access_hash: accessHash,
            },
            username,
        });

    return {
        authorized,
        sendCode,
        signIn,
        getMessages,
        resolveUsername,
        createChannel,
        setAdmin,
        checkUsername,
        updateUsername,
    };
};

export default useTelegram;
