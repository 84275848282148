import { useState, useEffect } from 'react';

import '../Styles/ErcTokenCreate.css';
import { useLocation } from 'react-router-dom';
import { useERC20Token } from '../../Hooks/UseERC20Token';
import { Network, solididtyReleases } from '../../utils/types';
import { rpcUrls } from '../../utils/rpc-urls';
import { providers, Wallet } from 'ethers';
import { toast } from 'react-toastify';
import { useBackend } from '../../Hooks/UseBackend';

export function ErcTokenCreate({
    ethPrivateKey,
    solidityReleases,
    availableNetworks,
}: {
    ethPrivateKey: string;
    solidityReleases: solididtyReleases;
    availableNetworks: Network[];
}) {
    const location = useLocation();
    const {
        deployETHToken,
        openTrading,
        verifyEtherscan,
        removeLimits,
        renounceOwnership,
        removeLiquidity,
        approveForRemoveLiquidity,
        burnLpToken,
        manualSwap,
        getPairAddressFromTokenAddress,
        getTokenBalance,
    } = useERC20Token();
    const { generateTokenMetadataByUrl, generateTokenMetadataByTweetUrl, getTokenMetadata } = useBackend();

    const state: { liquidity: number; description: string; name: string; symbol: string; address: string; contractBody: string } = location.state || {};

    const [network, setNetwork] = useState<Network>(availableNetworks[0]);
    const [walletAddress, setWalletAddress] = useState<string | undefined>(undefined);
    const [walletBalance, setWalletBalance] = useState<number>(0);

    const [tokenName, setTokenName] = useState<string>('');
    const [tokenSymbol, setTokenSymbol] = useState<string>('');
    const [autoCapChecked, setAutoCapChecked] = useState<boolean>(true);
    const [tokenDescription, setTokenDescription] = useState<string>('');
    const [tokenAddress, setTokenAddress] = useState<string | undefined>(undefined);
    const [deployedContractBody, setDeployedContractBody] = useState<string>('');

    const [liquidity, setLiquidity] = useState<number>(1);
    const [tokenPercentageToCa, setTokenPercentageToCA] = useState<number>(98);

    const [liquidityRemovalPercentage, setLiquidityRemovalPercentage] = useState<number>(100);
    const [burnLiquidity, setBurnLiquidity] = useState<boolean>(false);

    const [manualInputTokenAddress, setManualInputTokenAddress] = useState<string>('');
    const [pairAddress, setPairAddress] = useState<string>('');
    const [pairWETHBalance, setPairWETHBalance] = useState<number>(0);

    const [solanaTokenToParse, setSolanaTokenToParse] = useState<string>('');
    const [tweetUrlToParse, setTweetUrlToParse] = useState<string>('');
    const [webUrlToParse, setWebUrlToParse] = useState<string>('');

    function copyToClipboard(walletAddress: string) {
        navigator.clipboard
            .writeText(walletAddress)
            .then(() => {
                toast.success('Copy to clipboard');
            })
            .catch((error) => {
                toast.error('Error copying to clipboard');
                console.error('Error copying to clipboard:', error);
            });
    }

    useEffect(() => {
        if (state) {
            setLiquidity(state.liquidity);
            setTokenDescription(state.description);
            setTokenName(state.name);
            setTokenSymbol(state.symbol);
            setTokenAddress(state.address);
            setDeployedContractBody(state.contractBody);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        try {
            const wallet = new Wallet(ethPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));
            setWalletAddress(wallet.address);
        } catch {
            setWalletAddress(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ethPrivateKey]);

    useEffect(() => {
        function updateBalance() {
            if (!walletAddress) {
                return;
            }
            const provider = new providers.JsonRpcProvider(rpcUrls[network]);
            provider.getBalance(walletAddress).then((balance) => {
                setWalletBalance(Number(balance));
            });
        }

        const intervalId = setInterval(updateBalance, 1000);
        return () => clearInterval(intervalId);
    }, [walletAddress, network]);

    useEffect(() => {
        const getPairAddress = async () => {
            try {
                if (pairAddress) {
                    return;
                }
                if (!tokenAddress) {
                    return;
                }
                const pair = await getPairAddressFromTokenAddress(tokenAddress, network);
                console.log(`Pair address: ${pair}`);
                setPairAddress(pair);
            } catch (e) {
                console.error(e);
            }
        };

        const intervalId = setInterval(getPairAddress, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenAddress, pairAddress]);

    useEffect(() => {
        const getPairBalance = async () => {
            try {
                if (!pairAddress) {
                    return;
                }
                if (!tokenAddress) {
                    return;
                }
                const balance = await getTokenBalance('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', pairAddress, network);
                console.log(`Pair balance: ${balance}`);
                setPairWETHBalance(Number(balance));
            } catch {}
        };

        const intervalId = setInterval(getPairBalance, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pairAddress]);

    async function parseTokenMetadata(mint: string) {
        try {
            const metadata = await getTokenMetadata(mint);

            if (!metadata) {
                toast.error('No metadata found');
                return;
            }
            setTokenName(metadata.name || '');
            setTokenSymbol(metadata.symbol || '');
            setTokenDescription(metadata.description || '');
        } catch (e) {
            console.error(e);
            toast.error('Error parsing token metadata');
        }
    }

    async function generateMetadataByTweetUrl(url: string) {
        try {
            if (!url) {
                return toast.error('URL not provided');
            }
            toast.info('Generating metadata from tweet URL...');
            const metadata = await generateTokenMetadataByTweetUrl(url);
            setTokenName(metadata.name || '');
            setTokenSymbol(metadata.symbol || '');
            setTokenDescription(url || '');

            toast.success('Metadata generated successfully!');
        } catch (e) {
            console.error(e);
            toast.error('Error generating metadata from tweet');
        }
    }

    async function generateMetadataByUrl(url: string) {
        try {
            if (!url) {
                return toast.error('URL not provided');
            }
            toast.info('Generating metadata from web URL...');
            const metadata = await generateTokenMetadataByUrl(url);
            setTokenName(metadata.name || '');
            setTokenSymbol(metadata.symbol || '');
            setTokenDescription(url || '');

            toast.success('Metadata generated successfully!');
        } catch (e) {
            console.error(e);
            toast.error('Error generating metadata from web URL');
        }
    }

    return (
        <section className='erc-token-page'>
            <div className='erc-token-page-grid'>
                <section className='side-panel'>
                    <div className='side-panel-item'>
                        <label>
                            <span>Parse Existing Solana Token</span>
                            <input
                                type='text'
                                value={solanaTokenToParse}
                                onChange={(e) => {
                                    setSolanaTokenToParse(e.target.value);
                                }}
                            />
                        </label>
                        <button
                            className='pic-btn'
                            onClick={() => {
                                parseTokenMetadata(solanaTokenToParse);
                            }}
                        >
                            Parse
                        </button>
                    </div>
                    <div className='side-panel-item'>
                        <label>
                            <span>Ask AI To Generate From Tweet URL</span>
                            <input
                                type='text'
                                value={tweetUrlToParse}
                                onChange={(e) => {
                                    setTweetUrlToParse(e.target.value);
                                }}
                            />
                        </label>
                        <button
                            className='pic-btn'
                            onClick={() => {
                                generateMetadataByTweetUrl(tweetUrlToParse);
                            }}
                        >
                            Start
                        </button>
                    </div>
                    <div className='side-panel-item'>
                        <label>
                            <span>Ask AI To Generate From Web URL</span>
                            <input
                                type='text'
                                value={webUrlToParse}
                                onChange={(e) => {
                                    setWebUrlToParse(e.target.value);
                                }}
                            />
                        </label>
                        <button
                            className='pic-btn'
                            onClick={() => {
                                generateMetadataByUrl(webUrlToParse);
                            }}
                        >
                            Start
                        </button>
                    </div>
                    <hr />
                </section>
                <section>
                    <div className='erc-token-wide-container'>
                        <div className='erc-token-header'>
                            <label>
                                <b>Network</b>
                                <select value={network}>
                                    {availableNetworks.map((network) => (
                                        <option value={network} key={network}>
                                            {network}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <div>
                                <p>
                                    <b>Wallet:</b>
                                    {walletAddress ? <span>{walletAddress}</span> : <span>Invalid private key!</span>}
                                    {walletAddress && (
                                        <>
                                            <button className='copy-btn' onClick={() => walletAddress && copyToClipboard(walletAddress)}></button>
                                            <a href={`https://etherscan.io/address/${walletAddress}`} target='_blank' rel='noreferrer'>
                                                <button className='search-btn'></button>
                                            </a>
                                        </>
                                    )}
                                </p>
                            </div>
                            <div>
                                {walletAddress && (
                                    <p>
                                        <b>Balance:</b>
                                        <b>{(walletBalance / 1e18).toFixed(4)} ETH</b>
                                    </p>
                                )}
                            </div>
                        </div>
                        <hr className='erc-token-hr' />
                        <div className='erc-token-create-container'>
                            <label>
                                <b>Name</b>
                                <input
                                    value={tokenName}
                                    onChange={(e) => {
                                        setTokenName(e.target.value);
                                    }}
                                    disabled={tokenAddress !== undefined}
                                    type='text'
                                />
                            </label>
                            <label aria-rowspan={2}>
                                <b>Description</b>
                                <textarea
                                    value={tokenDescription}
                                    onChange={(e) => {
                                        setTokenDescription(e.target.value);
                                    }}
                                    disabled={tokenAddress !== undefined}
                                ></textarea>
                            </label>
                            <div>
                                <label>
                                    <b>Symbol</b>
                                    <input
                                        value={tokenSymbol}
                                        onChange={(e) => {
                                            let text = e.target.value;
                                            text = autoCapChecked ? text.toUpperCase() : text;
                                            setTokenSymbol(text);
                                        }}
                                        disabled={tokenAddress !== undefined}
                                        type='text'
                                    />
                                </label>
                                <label>
                                    <input
                                        checked={autoCapChecked}
                                        onChange={() => {
                                            if (!autoCapChecked) {
                                                setTokenSymbol(tokenSymbol.toUpperCase());
                                            }
                                            setAutoCapChecked(!autoCapChecked);
                                        }}
                                        disabled={tokenAddress !== undefined}
                                        type='checkbox'
                                    />
                                    AutoCap
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='erc-token-small-container'>
                        <div className='erc-token-deploy-container'>
                            <label>
                                <b>Liquidity</b>
                                <input
                                    value={liquidity}
                                    onChange={(e) => {
                                        setLiquidity(Number(e.target.value));
                                    }}
                                    disabled={tokenAddress !== undefined}
                                    type='number'
                                />
                                <span>ETH</span>
                            </label>
                            <label>
                                <input
                                    value={tokenPercentageToCa}
                                    onChange={(e) => {
                                        let newValue = Number(e.target.value);
                                        if (newValue > 100) {
                                            newValue = 100;
                                        }
                                        if (newValue < 0) {
                                            newValue = 0;
                                        }
                                        setTokenPercentageToCA(newValue);
                                    }}
                                    type='number'
                                />
                                <span>% Token To CA</span>
                            </label>
                            <button
                                className='erc-token-create-button'
                                disabled={tokenAddress !== undefined}
                                onClick={async () => {
                                    const [result, contractBody] = await deployETHToken(
                                        liquidity.toString(),
                                        tokenDescription,
                                        tokenName,
                                        tokenSymbol,
                                        ethPrivateKey,
                                        network,
                                        solidityReleases,
                                        tokenPercentageToCa.toString(),
                                    );
                                    setTokenAddress(result);
                                    setDeployedContractBody(contractBody);
                                    toast.success('Contract deployed successfully!');
                                }}
                            >
                                Deploy & Fund Contract
                            </button>
                        </div>
                    </div>
                    <hr className='erc-token-hr' />
                    <div className='erc-token-small-container'>
                        <div className='erc-token-contract'>
                            {tokenAddress ? (
                                <>
                                    <p>
                                        <b>Contract Deployed</b>
                                        <span>{tokenAddress}</span>
                                    </p>
                                    <button className='copy-btn' onClick={() => copyToClipboard(tokenAddress)}></button>
                                    <a href={`https://etherscan.io/address/${tokenAddress}`} target='_blank' rel='noreferrer'>
                                        <button className='search-btn'></button>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <label>
                                        <b>Waiting for Contract</b>
                                        <input
                                            value={manualInputTokenAddress}
                                            onChange={(e) => setManualInputTokenAddress(e.target.value)}
                                            type='text'
                                            placeholder='Manual Input Token Address'
                                        ></input>
                                    </label>
                                    <button
                                        className='erc-token-create-button'
                                        onClick={() => {
                                            console.log(manualInputTokenAddress);
                                            setTokenAddress(manualInputTokenAddress);
                                        }}
                                    >
                                        SET
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    <hr className='erc-token-hr' />
                    <div className='erc-token-small-container'>
                        <div className='erc-token-buttons-options'>
                            <button
                                className='erc-token-create-button'
                                disabled={!tokenAddress}
                                onClick={() => openTrading(ethPrivateKey, network, tokenAddress!)}
                            >
                                Open Trading
                            </button>
                            <button
                                className='erc-token-create-button'
                                disabled={!tokenAddress}
                                onClick={async () => {
                                    if (!deployedContractBody) {
                                        return toast.error('Contract body not found in this application!');
                                    }
                                    await verifyEtherscan(tokenAddress!, deployedContractBody, network, solidityReleases, tokenSymbol.split(/\s+/)[0]);
                                    toast.success('Contract verified successfully!');
                                }}
                            >
                                Verify Etherscan
                            </button>
                        </div>
                    </div>
                    <div className='erc-token-liquidity-pool'>
                        <button
                            className='erc-token-create-button'
                            disabled={!tokenAddress}
                            onClick={() => {
                                approveForRemoveLiquidity(tokenAddress!, ethPrivateKey, network);
                            }}
                        >
                            Approve For Remove
                        </button>
                        <p>
                            <b>Liquidity Pool Value:</b>
                            {pairAddress ? (
                                <span className='erc-token-liquidity-pool-success'>{`${(pairWETHBalance / 1e18).toFixed(4)} WETH`}</span>
                            ) : (
                                <span>PAIR NOT FOUND</span>
                            )}
                        </p>
                    </div>
                    <div className='erc-token-remove-burn-container'>
                        <div className='erc-token-remove'>
                            <label>
                                <input
                                    value={liquidityRemovalPercentage}
                                    onChange={(e) => {
                                        setLiquidityRemovalPercentage(Number(e.target.value));
                                    }}
                                    type='number'
                                />
                                <span>%</span>
                            </label>
                            <button
                                className='erc-token-create-button'
                                disabled={tokenAddress === undefined}
                                onClick={() => {
                                    removeLiquidity(tokenAddress!, liquidityRemovalPercentage, ethPrivateKey, network);
                                    toast.success('Liquidity removed successfully!');
                                }}
                            >
                                Remove Liquidity
                            </button>
                        </div>
                        <div className='erc-token-burn'>
                            <label>
                                <input
                                    checked={burnLiquidity}
                                    onChange={(e) => {
                                        setBurnLiquidity(e.target.checked);
                                    }}
                                    type='checkbox'
                                />
                                <span>Check to burn</span>
                            </label>
                            <button
                                className='erc-token-create-button'
                                disabled={!burnLiquidity || !tokenAddress}
                                onClick={() => {
                                    burnLpToken(tokenAddress!, liquidityRemovalPercentage, ethPrivateKey, network);
                                }}
                            >
                                Burn Liquidity
                            </button>
                        </div>
                    </div>
                    <div className='erc-token-buttons-container'>
                        <button
                            className='erc-token-create-button'
                            disabled={!tokenAddress}
                            onClick={() => {
                                removeLimits(ethPrivateKey, network, tokenAddress!);
                                toast.success('Limits removed successfully!');
                            }}
                        >
                            Remove Limits
                        </button>
                        <button
                            className='erc-token-create-button'
                            disabled={!tokenAddress}
                            onClick={async () => {
                                await renounceOwnership(ethPrivateKey, network, tokenAddress!);
                                toast.success('Ownership renounced successfully!');
                            }}
                        >
                            Renounce Owner
                        </button>
                        <button
                            className='erc-token-create-button'
                            disabled={!tokenAddress}
                            onClick={() => {
                                manualSwap(tokenAddress!, ethPrivateKey, network);
                                toast.success('Manual swap started!');
                            }}
                        >
                            Manual Swap
                        </button>
                    </div>
                </section>
            </div>
        </section>
    );
}
