import { toast } from 'react-toastify';
import { getContractBody } from '../utils/get-contract-body';
import axios from 'axios';
import { Network, solididtyReleases } from '../utils/types';
import { solidityCompiler } from '@agnostico/browser-solidity-compiler';
import { ContractFactory, ethers, providers, Wallet } from 'ethers';
import { rpcUrls } from '../utils/rpc-urls';

export const useERC20Token = () => {
    const solidityVersion = '0.8.25';

    const verifyEtherscan = async (
        contractAddress: string,
        contractBody: string,
        network: Network,
        solidityReleases: solididtyReleases,
        contractName: string,
    ) => {
        const etherscanApiUrl: Record<Network, string> = {
            fantom: 'https://api.ftmscan.com/api',
            mainnet: 'https://api.etherscan.io/api',
        };
        const scanApiKey: Record<Network, string | undefined> = {
            fantom: process.env.REACT_APP_FANTOMSCAN_API_KEY,
            mainnet: process.env.REACT_APP_ETHERSCAN_API_KEY,
        };
        if (contractAddress === undefined) {
            toast.error('Contract is not deployed!');
            return;
        }
        toast.info('Trying to verify...');

        await axios
            .post(
                etherscanApiUrl[network],
                {
                    apikey: scanApiKey[network],
                    module: 'contract',
                    action: 'verifysourcecode',
                    contractaddress: contractAddress,
                    sourceCode: contractBody,
                    codeformat: 'solidity-single-file',
                    contractname: contractName,
                    compilerversion: solidityReleases[solidityVersion].replace('soljson-', '').replace('.js', ''),
                    optimizationUsed: 1,
                    runs: 200,
                    licenseType: 3,
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                },
            )
            .then((response) => {
                response.data.message === 'OK' ? toast.success('Contract verified!') : toast.error('Failed to verify!');
                console.log(response.data);
            });
    };

    const deployETHToken = async (
        initLiquidity: string,
        description: string,
        name: string,
        symbol: string,
        ethPrivateKey: string,
        network: Network,
        solidityReleases: solididtyReleases,
        tokensToCA: string,
    ): Promise<string[]> => {
        toast.info(`Deploying ${name} token...`);
        const contractName = symbol.split(/\s+/)[0];
        const contractBody = await getContractBody(description, solidityVersion, contractName, name, symbol, tokensToCA);

        console.log('Compiling');
        console.log(`https://binaries.soliditylang.org/bin/${solidityReleases[solidityVersion]}`);
        console.log(contractBody);
        const [bytecode, abi] = await solidityCompiler({
            version: `https://binaries.soliditylang.org/bin/${solidityReleases[solidityVersion]}`,
            contractBody,
            options: {
                optimizer: {
                    enabled: true,
                    runs: 200,
                },
            },
        }).then((result: any) => {
            console.log(result);
            console.log('Compiled');
            console.log(`all names`, Object.keys(result.contracts.Compiled_Contracts));
            console.log('my contract', result.contracts.Compiled_Contracts[contractName]);

            return [result.contracts.Compiled_Contracts[contractName].evm.bytecode.object, result.contracts.Compiled_Contracts[contractName].abi];
        });
        console.log(`bytecode: ${bytecode}`);
        const wallet = new Wallet(ethPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));

        console.log(`provider: ${rpcUrls[network]}`);
        console.log(`wallet: ${wallet.address}`);
        const contractFactory = new ContractFactory(abi, bytecode, wallet);

        console.log('factory');
        const value = ethers.utils.parseEther(initLiquidity);
        console.log(`value: ${value.toString()}`);
        const contract = await contractFactory.deploy({ value: value });
        await contract.deployed();

        console.log(`Contract deployed at address: ${contract.address}`);
        toast.info(`Contract deployed at address: ${contract.address}`);

        const result = contract.address;
        return [result, contractBody];
    };

    const openTrading = async (deployerPrivateKey: string, network: Network, deployedContractAddress: string) => {
        toast.info('Open Trading...');
        const ABI = `[
            {
              "inputs": [],
              "name": "openTrading",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            }
          ]`;

        const wallet = new Wallet(deployerPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));

        const tokenContract = new ethers.Contract(deployedContractAddress, ABI, wallet);

        const transaction = await tokenContract.openTrading();
        await transaction.wait();
        toast.success('Success Open Trading');
    };

    const removeLimits = async (deployerPrivateKey: string, network: Network, deployedContractAddress: string) => {
        toast.info('Removing limits...');
        const ABI = `[
            {
              "inputs": [],
              "name": "removeLimits",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            }
          ]`;

        const wallet = new Wallet(deployerPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));

        const tokenContract = new ethers.Contract(deployedContractAddress, ABI, wallet);

        const transaction = await tokenContract.removeLimits();
        await transaction.wait();

        toast.success('Success Remove Limits');
    };

    const renounceOwnership = async (deployerPrivateKey: string, network: Network, deployedContractAddress: string) => {
        toast.info('Renouncing Ownership...');
        const ABI = `[
            {
              "inputs": [],
              "name": "renounceOwnership",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            }
          ]`;

        const wallet = new Wallet(deployerPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));

        const tokenContract = new ethers.Contract(deployedContractAddress, ABI, wallet);

        const transaction = await tokenContract.renounceOwnership();
        await transaction.wait();

        toast.success('Success Renounce Ownership');
    };

    const approveForRemoveLiquidity = async (tokenA: string, deployerPrivateKey: string, network: Network) => {
        toast.info('Approving for remove liquidity...');

        const wallet = new Wallet(deployerPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));
        const uniswapV2RouterAddress = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';

        const uniswapV2FactoryAddress = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';
        const uniswapV2FactoryABI = `
        [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "tokenA",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "tokenB",
                        "type": "address"
                    }
                ],
                "name": "getPair",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "pair",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ]
        `;
        const uniswapV2Factory = new ethers.Contract(uniswapV2FactoryAddress, uniswapV2FactoryABI, wallet);

        const pairAddress = await uniswapV2Factory.getPair(tokenA, '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2');
        const pairABI = `[
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "spender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "approve",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            }
        ]
        `;
        console.log(`pairAddress: ${pairAddress}`);
        const pair = new ethers.Contract(pairAddress, pairABI, wallet);
        const transaction = await pair.approve(uniswapV2RouterAddress, ethers.constants.MaxUint256);
        toast.info(`Transaction sent: ${transaction.hash}`);

        await transaction.wait();
        toast.success('Success Approve for Remove Liquidity');
    };

    const removeLiquidity = async (tokenA: string, removePercentage: number, deployerPrivateKey: string, network: Network) => {
        toast.info('Removing liquidity...');
        const wallet = new Wallet(deployerPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));
        const routerABI = `
        [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "token",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "liquidity",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountTokenMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountETHMin",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "to",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "deadline",
                        "type": "uint256"
                    }
                ],
                "name": "removeLiquidityETHSupportingFeeOnTransferTokens",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "amountETH",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            }
        ]
        `;
        const uniswapV2RouterAddress = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
        const uniswapV2Router = new ethers.Contract(uniswapV2RouterAddress, routerABI, wallet);

        const pairAddress = await getPairAddressFromTokenAddress(tokenA, network);
        const pairABI = `
        [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ]
        `;
        console.log(`pairAddress: ${pairAddress}`);
        const pair = new ethers.Contract(pairAddress, pairABI, wallet);
        const balance = await pair.balanceOf(wallet.address);
        console.log(`balance: ${balance.toString()}`);
        const liquidity = balance.mul(removePercentage).div(100).toString();
        console.log(`liquidity: ${liquidity.toString()}`);
        const transaction = await uniswapV2Router.removeLiquidityETHSupportingFeeOnTransferTokens(
            tokenA,
            liquidity,
            0,
            0,
            wallet.address,
            ethers.constants.MaxUint256,
        );
        toast.info(`Transaction sent: ${transaction}`);
        await transaction.wait();

        toast.success('Success Remove Liquidity');
    };

    const burnLpToken = async (tokenA: string, burnPercentage: number, deployerPrivateKey: string, network: Network) => {
        toast.info('Removing liquidity...');
        const wallet = new Wallet(deployerPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));

        const pairAddress = await getPairAddressFromTokenAddress(tokenA, network);
        const pairABI = `
        [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "transfer",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "nonpayable",
                "type": "function"
            }
        ]
        `;
        console.log(`pairAddress: ${pairAddress}`);
        const pair = new ethers.Contract(pairAddress, pairABI, wallet);
        const balance = await pair.balanceOf(wallet.address);
        console.log(`balance: ${balance.toString()}`);
        const liquidity = balance.mul(burnPercentage).div(100).toString();
        console.log(`liquidity: ${liquidity.toString()}`);

        const tx = await pair.transfer('0x000000000000000000000000000000000000dead', liquidity);
        await tx.wait();
        toast.success('Success Burn LP Token');
    };

    const manualSwap = async (tokenA: string, deployerPrivateKey: string, network: Network) => {
        const tokenAbi = `[
	    {
		"inputs": [],
		"name": "manualSwap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	    }]`;

        const wallet = new Wallet(deployerPrivateKey, new providers.JsonRpcProvider(rpcUrls[network]));
        const tokenContract = new ethers.Contract(tokenA, tokenAbi, wallet);
        const tx = await tokenContract.manualSwap();
        toast.info(`Transaction sent: ${tx.hash}`);
        await tx.wait(1);
        toast.success('Success Manual Swap');
    };

    const getPairAddressFromTokenAddress = async (tokenA: string, network: Network) => {
        const randomWallet = ethers.Wallet.createRandom().connect(new providers.JsonRpcProvider(rpcUrls[network]));
        const uniswapV2FactoryAddress = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';
        const uniswapV2FactoryABI = `
        [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "tokenA",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "tokenB",
                        "type": "address"
                    }
                ],
                "name": "getPair",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "pair",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ]
        `;
        const uniswapV2Factory = new ethers.Contract(uniswapV2FactoryAddress, uniswapV2FactoryABI, randomWallet);
        console.log(`#{tokenA}: ${tokenA}`);
        const pairAddress = await uniswapV2Factory.getPair(tokenA, '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2');
        console.log(`pairAddress: ${pairAddress}`);
        if (pairAddress === '0x0000000000000000000000000000000000000000') {
            throw new Error('Pair address not found!');
        }
        return pairAddress;
    };

    const getTokenBalance = async (tokenAddress: string, walletAddress: string, network: Network) => {
        const tokenABI = `
        [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ]
        `;

        const randomWallet = ethers.Wallet.createRandom().connect(new providers.JsonRpcProvider(rpcUrls[network]));
        const tokenContract = new ethers.Contract(tokenAddress, tokenABI, randomWallet);
        console.log(`tokenAddress: ${tokenAddress}`);
        console.log(`walletAddress: ${walletAddress}`);
        const balance = await tokenContract.balanceOf(walletAddress);

        return balance;
    };

    return {
        verifyEtherscan,
        deployETHToken,
        openTrading,
        removeLimits,
        renounceOwnership,
        removeLiquidity,
        approveForRemoveLiquidity,
        burnLpToken,
        manualSwap,
        getPairAddressFromTokenAddress,
        getTokenBalance,
    };
};
