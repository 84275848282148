import { Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { ParserBackend } from '../utils/parser-backend';
import { Keypair } from '@solana/web3.js';
import { Api } from '@sodamnfoolish/sjc-backend-types';

export const useBackend = () => {
    const parserBackend = new ParserBackend(process.env.REACT_APP_BACKEND_URL!, process.env.REACT_APP_BACKEND_APIKEY!);

    const getSources = async () => {
        const sources = await parserBackend.getSources();
        return sources;
    };

    const getFeed = async (sources: Source[], size = 20) => {
        const posts = await parserBackend.getFeed(sources, size);
        return posts;
    };

    const getMedia = async (id: string) => {
        const media = await parserBackend.getMedia(id);
        return media;
    };

    const getVanity = async () => {
        const vanity = await parserBackend.getVanity();
        return Keypair.fromSecretKey(Uint8Array.from(vanity));
    };

    const getChatGptMetadataFromPostId = async (postId: string) => {
        const chatGptMetadata = await parserBackend.getChatGptMetadataFromPostId(postId);
        return chatGptMetadata;
    };

    const generateTokenMetadataByTweetUrl = async (tweetUrl: string) => {
        console.log('tweet url', tweetUrl);

        const tokenMetadata = await parserBackend.generateTokenMetadataByTweetUrl(encodeURIComponent(tweetUrl));
        return tokenMetadata;
    };

    const generateTokenMetadataByUrl = async (url: string) => {
        console.log('url', url);

        const tokenMetadata = await parserBackend.generateTokenMetadataByUrl(encodeURIComponent(url));
        return tokenMetadata;
    };

    const getTokenMetadata = async (mint: string) => {
        const tokenMetadata = await parserBackend.getTokenMetadata(mint);
        return tokenMetadata;
    };

    const deployToken = async (deployRequest: Api.Wallet.DTO.DeployRequest, wallet: string) => {
        console.log('deploy request', deployRequest);
        const deployResponse = await parserBackend.deployToken(deployRequest, wallet);
        console.log('deploy response', deployResponse);
        const mint = deployResponse.mint;
        const signature = deployResponse.signature;

        return { mint, signature };
    };

    const sellToken = async (sellRequest: Api.Wallet.DTO.SellRequest, wallet: string) => {
        const signature = await parserBackend.sellToken(sellRequest, wallet);
        return signature;
    };

    const transferToken = async (transferRequest: Api.Wallet.DTO.TransferRequest, wallet: string) => {
        const signature = await parserBackend.transferToken(transferRequest, wallet);
        return signature;
    };

    return {
        getSources,
        getFeed,
        getMedia,
        getVanity,
        getChatGptMetadataFromPostId,
        generateTokenMetadataByTweetUrl,
        generateTokenMetadataByUrl,
        getTokenMetadata,
        deployToken,
        sellToken,
        transferToken,
    };
};
