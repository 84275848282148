import '../Styles/ResultPage.css';
import MessageCard from '../MessageCard';
import { useLocation } from 'react-router-dom';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { getTwitterIdFromUrl } from '../../utils/ge-tweet-id-from-url';
import { Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto/post';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useUrlParams } from '../../Hooks/useUrlParams';
import { getAssociatedTokenAddressSync, unpackAccount } from '@solana/spl-token';
import { Keypair, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { useBackend } from '../../Hooks/UseBackend';
import { Api } from '@sodamnfoolish/sjc-backend-types';
import * as pumpfun from '../../utils/pumpfun/pump-fun';
import { AnchorProvider, BN, Program } from '@coral-xyz/anchor';
import MyWallet from '../../utils/pumpfun/my-wallet';
import pumpProgramIdl from '../../utils/pumpfun/pump-program';
import { connection } from '../../utils/connection';

export function Result({ sellJito, sellPrio }: { sellJito: number; sellPrio: number }) {
    const location = useLocation();
    const { sellToken, transferToken } = useBackend();
    const { walletAddress } = useUrlParams();
    const {
        post,
        background,
        platform,
        mint,
        name,
        symbol,
        initBuy,
        takeProfitPercent,
        autoSellDelaySeconds,
        imageFile,
    }: {
        post: Post;
        background: string;
        platform: string;
        mint: string;
        name: string;
        symbol: string;
        initBuy: number;
        takeProfitPercent: number;
        autoSellDelaySeconds: number;
        imageFile: File;
    } = location.state || {};
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    const [solProfit, setSolProfit] = useState<number>(0);

    const [autoSellTimeLeft, setAutoSellTimeLeft] = useState<number>(autoSellDelaySeconds);
    const [autoSellTimer, setAutoSellTimer] = useState<boolean>(true);
    const [autoSellProfit, setAutoSellProfit] = useState<boolean>(true);
    const [currentProfitPercent, setCurrentProfitPercent] = useState<number>(0);

    const [tokenBalance, setTokenBalance] = useState<string>('0');
    const [transferPercent, setTransferPercent] = useState<number>(100);
    const [transferWallet, setTransferWallet] = useState<string>('');

    const bondingCurvePubkey = pumpfun.getBondingCurvePubkey(new PublicKey(mint));
    useEffect(() => {
        if (!walletAddress) return;
        if (!mint) return;

        const tokenAccount = getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(walletAddress));
        connection.getTokenAccountBalance(tokenAccount, 'processed').then((res) => {
            setTokenBalance(res.value.uiAmountString || '0');
        });
        const subId = connection.onAccountChange(
            tokenAccount,
            async (accountInfo) => {
                const account = unpackAccount(tokenAccount, accountInfo);
                const balance = (account.amount / BigInt(1e6)).toString();
                setTokenBalance(balance);
            },
            { commitment: 'processed' },
        );

        return () => {
            connection.removeAccountChangeListener(subId);
        };
    }, [walletAddress, mint, bondingCurvePubkey]);

    useEffect(() => {
        if (imageFile) {
            const imageUrl = URL.createObjectURL(imageFile);
            setImageSrc(imageUrl);
            return () => URL.revokeObjectURL(imageUrl);
        }
    }, [imageFile]);

    useEffect(() => {
        if (!walletAddress) return;
        const provider = new AnchorProvider(connection, new MyWallet(Keypair.generate()), AnchorProvider.defaultOptions());
        const program = new Program(pumpProgramIdl, pumpfun.PROGRAM_PUBKEY, provider);

        const subId = connection.onAccountChange(
            bondingCurvePubkey,
            async (accountInfo) => {
                const bondingCurveData = program.coder.accounts.decode('BondingCurve', accountInfo.data) as pumpfun.BondingCurveData;
                const tokenAccount = getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(walletAddress));
                const balanceResponce = (await connection.getTokenAccountBalance(tokenAccount)).value;
                setTokenBalance(balanceResponce.uiAmountString || '0');
                const balance = balanceResponce.amount;
                const tokenWorth = pumpfun.getMinSolAmount(new BN(balance), bondingCurveData);

                const solProfit = tokenWorth.toNumber() - initBuy * LAMPORTS_PER_SOL;

                setSolProfit(solProfit);

                const currentProfitPercent = (solProfit / (initBuy * LAMPORTS_PER_SOL)) * 100;
                setCurrentProfitPercent(currentProfitPercent);

                if (autoSellProfit && currentProfitPercent >= takeProfitPercent) {
                    console.log('current profit percent', currentProfitPercent);
                    console.log('take profit percent', takeProfitPercent);
                    sell(100);
                    setAutoSellProfit(false);
                }
            },
            {
                commitment: 'processed',
            },
        );

        return () => {
            connection.removeAccountChangeListener(subId);
        };
    }, [walletAddress, mint, initBuy, autoSellProfit]);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (autoSellTimer && autoSellTimeLeft > 0) {
            interval = setInterval(() => {
                setAutoSellTimeLeft((prev) => {
                    if (prev > 0) {
                        return prev - 1;
                    } else {
                        clearInterval(interval!);
                        setAutoSellTimer(false);
                        sell(100);
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [autoSellTimer, autoSellTimeLeft]);

    function copyToClipboard(mint: string) {
        navigator.clipboard
            .writeText(mint)
            .then(() => {
                toast.success('Copy to clipboard');
            })
            .catch((error) => {
                toast.error('Error copying to clipboard');
                console.error('Error copying to clipboard:', error);
            });
    }

    async function sell(percent: number) {
        try {
            if (!walletAddress) {
                toast.error('Wallet address not found');
                return;
            }
            const ata = getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(walletAddress));
            console.log('ata', ata.toBase58());
            console.log('ata', ata);

            const balanceResponce = (await connection.getTokenAccountBalance(ata)).value;
            if (balanceResponce.uiAmount === 0) {
                toast.error('No balance');
                return;
            }
            toast.info(`Selling ${(balanceResponce.uiAmount! * percent) / 100} ${symbol}...`);
            const sellAmount = (Number(balanceResponce.amount) * percent) / 100;

            const sellRequest: Api.Wallet.DTO.SellRequest = {
                mint,
                tokenAmount: sellAmount,
                priorityFee: sellPrio,
                jitoTip: sellJito * LAMPORTS_PER_SOL,
            };

            const sign = await sellToken(sellRequest, walletAddress);
            toast.success(`Selling tx sent ${sign}`);
        } catch (error) {
            toast.error('Error selling');
            console.error('Error selling:', error);
        }
    }

    async function transfer(sender: string, receiver: string, percent: number) {
        try {
            toast.info(`Transferring ${percent}% to ${receiver}...`);
            const senderAta = getAssociatedTokenAddressSync(new PublicKey(mint), new PublicKey(sender));
            const balance = await connection.getTokenAccountBalance(senderAta, 'processed');
            if (!balance.value) {
                return toast.error('No balance');
            }
            const amount = Math.floor((Number(balance.value.amount) * percent) / 100);

            const transferRequest: Api.Wallet.DTO.TransferRequest = {
                mint,
                to: receiver,
                amount,
                priorityFee: sellPrio,
                jitoTip: sellJito * LAMPORTS_PER_SOL,
            };

            const sign = await transferToken(transferRequest, sender);
            toast.success(`Transfer tx sent ${sign}`);
        } catch (error) {
            toast.error('Error transferring');
            console.error('Error transferring:', error);
        }
    }

    return (
        <section className='card-info-container'>
            <section className='card'>
                <div className='mes-card-form'>
                    {post ? (
                        <MessageCard
                            post={post}
                            background={background}
                            platform={platform}
                            username={post.username}
                            solInitialBuyThunder={0}
                            autoSellDelayThunder={0}
                            autoSellProfitThunder={0}
                            buyJitoTip={0}
                            buyPrio={0}
                        />
                    ) : (
                        <p>Post not selected</p>
                    )}
                </div>
                <div className='tweetForm'>
                    {post && (post.url.includes('twitter.com') || post.url.includes('x.com')) ? (
                        <div data-theme='dark'>
                            <div
                                data-theme='dark'
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    overflow: 'hidden',
                                }}
                            >
                                <TwitterTweetEmbed tweetId={getTwitterIdFromUrl(post.url)} options={{ theme: 'dark' }} />
                            </div>
                        </div>
                    ) : (
                        <p></p>
                    )}
                </div>
            </section>
            <section className='pump-info'>
                <div className='icon-link'>
                    <div
                        className='pump-icon'
                        onClick={() => {
                            window.open(`https://pump.fun/${mint}`, '_blank');
                        }}
                    ></div>
                    <div className='pump-token-adress'>
                        <h3>Instant AI Token Created!</h3>
                        <p
                            onClick={() => {
                                window.open(`https://pump.fun/${mint}`, '_blank');
                            }}
                        >
                            {mint}
                        </p>
                        <button className='copy-clipboard' onClick={() => copyToClipboard(mint)}></button>
                    </div>
                </div>
                <div className='name-symbol-image'>
                    <div className='name-symbol'>
                        <div className='name'>
                            <h3>Name: {name}</h3>
                        </div>
                        <div className='symbol'>
                            <h3>Symbol: {symbol}</h3>
                        </div>
                        <div className='initial-buy'>
                            <h3>Initial Buy: {initBuy.toFixed(3)}</h3>
                        </div>
                    </div>
                    <div className='token-image'>
                        <h3>Image</h3>
                        <div className='token-img'>
                            {imageSrc && (
                                <img
                                    src={imageSrc}
                                    alt='Token'
                                    className='image-preview'
                                    onClick={() => {
                                        window.open(`https://pump.fun/${mint}`, '_blank');
                                    }}
                                />
                            )}
                        </div>
                        <div
                            className='pump-icon'
                            onClick={() => {
                                window.open(`https://pump.fun/${mint}`, '_blank');
                            }}
                        ></div>
                    </div>
                </div>
                <hr />
                {autoSellTimer ? (
                    <div className='autosell-in'>
                        <div className='autosell-text'>
                            <h3>Auto Sell In</h3>
                            <p>{autoSellTimeLeft} Sec</p>
                        </div>
                        <button className='sell-btn' onClick={() => setAutoSellTimer(false)}>
                            Cancel
                        </button>
                    </div>
                ) : null}
                <div className='profit'>
                    <div className='profit-text'>
                        <h3>Profit</h3>
                        <div className='profit-percent-text'>
                            <h2 className='percent-sol'>{`${(solProfit / LAMPORTS_PER_SOL).toFixed(2)} (${currentProfitPercent.toFixed(2)}%)`}</h2>
                            {autoSellProfit ? <p>Auto Sell At {takeProfitPercent}% Profit Goal</p> : null}
                        </div>
                    </div>
                    {autoSellProfit ? (
                        <button className='sell-btn' onClick={() => setAutoSellProfit(false)}>
                            Cancel
                        </button>
                    ) : null}
                </div>
                <div className='button-container'>
                    <button
                        className='sell-btn'
                        onClick={() => {
                            sell(100);
                        }}
                    >
                        Sell 100%
                    </button>
                    <button
                        className='sell-btn'
                        onClick={() => {
                            sell(50);
                        }}
                    >
                        Sell 50%
                    </button>
                    <button
                        className='sell-btn'
                        onClick={() => {
                            sell(25);
                        }}
                    >
                        Sell 25%
                    </button>
                    <button
                        className='sell-btn'
                        onClick={() => {
                            sell(10);
                        }}
                    >
                        Sell 10%
                    </button>
                    <button
                        className='sell-btn'
                        onClick={() => {
                            sell(5);
                        }}
                    >
                        Sell 5%
                    </button>
                    <button
                        className='sell-btn'
                        onClick={() => {
                            sell(1);
                        }}
                    >
                        Sell 1%
                    </button>
                </div>
                <div className='tkn-balance'>
                    <h3>Token Balance:</h3>
                    <p>{tokenBalance}</p>
                </div>
                <div className='transfer-tkns'>
                    <h3>Transfer Tokens To Wallet</h3>
                    <div className='inp-pecrent'>
                        <input
                            type='number'
                            className='pecrent-inp'
                            value={transferPercent}
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                if (value >= 0 && value <= 100) {
                                    setTransferPercent(value);
                                } else if (value < 0) {
                                    setTransferPercent(0);
                                } else if (value > 100) {
                                    setTransferPercent(100);
                                }
                            }}
                        />
                        <h3>%</h3>
                    </div>
                    <input
                        type='string'
                        className='wallet-inp'
                        value={transferWallet}
                        onChange={(e) => {
                            setTransferWallet(e.target.value);
                        }}
                    />
                    <button
                        className='sell-btn'
                        onClick={() => {
                            transfer(walletAddress, transferWallet, transferPercent);
                        }}
                    >
                        Transfer
                    </button>
                </div>
            </section>
        </section>
    );
}
export default Result;
