import './styles.css';
import { Navbar } from './Components/Navbar';
import { MonitorFeed } from './Components/pages/MonitorFeed';
import { Route, Routes, useLocation } from 'react-router-dom';
import { TokenCreate } from './Components/pages/TokenCreate';
import { Header } from './Components/Header';
import { useEffect, useState } from 'react';
import { useBackend } from './Hooks/UseBackend';
import { Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { ToastContainer } from 'react-toastify';
import { Result } from './Components/pages/ResultPage';
import { Settings } from './Components/pages/Settings';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ErcTokenCreate } from './Components/pages/ErcTokenCreate';
import { getCompilerVersions } from '@agnostico/browser-solidity-compiler';
import { Network, solididtyReleases } from './utils/types';

function App() {
    const availableNetworks: Network[] = ['mainnet', 'fantom'];

    const [sources, setSources] = useState<Source[]>([]);
    const [visibleSources, setVisibleSources] = useState<Source[]>([]);
    const [isGeneralPostsVisible, setGeneralPostsVisible] = useState(true);
    const [sourceOrder, setSourceOrder] = useState<string[]>(() => {
        const savedOrder = localStorage.getItem('sourceOrder');
        return savedOrder ? JSON.parse(savedOrder) : [];
    });

    const [solidityReleases, setSolidityReleases] = useState<solididtyReleases>({});
    useEffect(() => {
        getCompilerVersions().then((response: any) => setSolidityReleases(response.releases));
    }, []);

    const location = useLocation();
    const locationWithoutHeader = ['/Settings', '/ResultPage', '/TokenCreate', '/ErcTokenCreate'].includes(location.pathname);

    const [solInitialBuyThunder, setSolInitialBuyThunder] = useState<number>(() => {
        const saved = localStorage.getItem('solInitialBuyThunder');
        return saved ? Number(saved) : 3;
    });

    const [solInitialBuyArrow, setSolInitialBuyArrow] = useState<number>(() => {
        const saved = localStorage.getItem('solInitialBuyArrow');
        return saved ? Number(saved) : 3;
    });

    const [solInitialBuyCopy, setSolInitialBuyCopy] = useState<number>(() => {
        const saved = localStorage.getItem('solInitialBuyCopy');
        return saved ? Number(saved) : 3;
    });

    const [buyPrio, setBuyPrio] = useState<number>(() => {
        const saved = localStorage.getItem('buyPrio');
        return saved ? Number(saved) : 4000000;
    });

    const [sellPrio, setSellPrio] = useState<number>(() => {
        const saved = localStorage.getItem('sellPrio');
        return saved ? Number(saved) : 4000000;
    });

    const [buyJito, setBuyJito] = useState<number>(() => {
        const saved = localStorage.getItem('buyJito');
        return saved ? Number(saved) : 0.001;
    });

    const [sellJito, setSellJito] = useState<number>(() => {
        const saved = localStorage.getItem('sellJito');
        return saved ? Number(saved) : 0.001;
    });

    const [autoSellDelayThunder, setAutoSellDelayThunder] = useState<number>(() => {
        const saved = localStorage.getItem('autoSellDelayThunder');
        return saved ? Number(saved) : 10;
    });

    const [autoSellProfitThunder, setAutoSellProfitThunder] = useState<number>(() => {
        const saved = localStorage.getItem('autoSellProfitThunder');
        return saved ? Number(saved) : 50;
    });

    const [autoSellDelayArrow, setAutoSellDelayArrow] = useState<number>(() => {
        const saved = localStorage.getItem('autoSellDelayArrow');
        return saved ? Number(saved) : 10;
    });

    const [autoSellProfitArrow, setAutoSellProfitArrow] = useState<number>(() => {
        const saved = localStorage.getItem('autoSellProfitArrow');
        return saved ? Number(saved) : 50;
    });

    const [autoSellDelayCreation, setAutoSellDelayCopy] = useState<number>(() => {
        const saved = localStorage.getItem('autoSellDelayCreation');
        return saved ? Number(saved) : 10;
    });

    const [autoSellProfitCreation, setAutoSellProfitCopy] = useState<number>(() => {
        const saved = localStorage.getItem('autoSellProfitCreation');
        return saved ? Number(saved) : 50;
    });

    const [ethPrivateKey, setEthPrivateKey] = useState<string>(() => {
        const saved = localStorage.getItem('ethPrivateKey');
        return saved ? saved : '';
    });

    const [solanaPrivateKey, setSolanaPrivateKey] = useState<string>(() => {
        const saved = localStorage.getItem('solanaPrivateKey');
        return saved ? saved : '';
    });

    useEffect(() => {
        localStorage.setItem('solInitialBuyThunder', solInitialBuyThunder.toString());
    }, [solInitialBuyThunder]);

    useEffect(() => {
        localStorage.setItem('solInitialBuyArrow', solInitialBuyArrow.toString());
    }, [solInitialBuyArrow]);

    useEffect(() => {
        localStorage.setItem('solInitialBuyCopy', solInitialBuyCopy.toString());
    }, [solInitialBuyCopy]);

    useEffect(() => {
        localStorage.setItem('buyPrio', buyPrio.toString());
    }, [buyPrio]);

    useEffect(() => {
        localStorage.setItem('sellPrio', sellPrio.toString());
    }, [sellPrio]);

    useEffect(() => {
        localStorage.setItem('buyJito', buyJito.toString());
    }, [buyJito]);

    useEffect(() => {
        localStorage.setItem('sellJito', sellJito.toString());
    }, [sellJito]);

    useEffect(() => {
        localStorage.setItem('autoSellDelayThunder', autoSellDelayThunder.toString());
    }, [autoSellDelayThunder]);

    useEffect(() => {
        localStorage.setItem('autoSellProfitThunder', autoSellProfitThunder.toString());
    }, [autoSellProfitThunder]);

    useEffect(() => {
        localStorage.setItem('autoSellDelayArrow', autoSellDelayArrow.toString());
    }, [autoSellDelayArrow]);

    useEffect(() => {
        localStorage.setItem('autoSellProfitArrow', autoSellProfitArrow.toString());
    }, [autoSellProfitArrow]);

    useEffect(() => {
        localStorage.setItem('autoSellDelayCreation', autoSellDelayCreation.toString());
    }, [autoSellDelayCreation]);

    useEffect(() => {
        localStorage.setItem('autoSellProfitCreation', autoSellProfitCreation.toString());
    }, [autoSellProfitCreation]);

    useEffect(() => {
        localStorage.setItem('ethPrivateKey', ethPrivateKey);
    }, [ethPrivateKey]);

    useEffect(() => {
        localStorage.setItem('solanaPrivateKey', solanaPrivateKey);
    }, [solanaPrivateKey]);

    const { getSources } = useBackend();

    const [tweetTypes, setTweetTypes] = useState<{
        [key: string]: { selectedTweetTypes: Set<string>; soundTweetTypes: Set<string> };
    }>(() => {
        const initialTweetTypes: {
            [key: string]: { selectedTweetTypes: Set<string>; soundTweetTypes: Set<string> };
        } = {};

        sources.forEach((source) => {
            const selectedSaved = localStorage.getItem(`selectedTweetTypes_${source.username}`);
            const soundSaved = localStorage.getItem(`soundTweetTypes_${source.username}`);

            initialTweetTypes[source.username] = {
                selectedTweetTypes: selectedSaved ? new Set(JSON.parse(selectedSaved)) : new Set(['tweet', 'retweet', 'reply']),
                soundTweetTypes: soundSaved ? new Set(JSON.parse(soundSaved)) : new Set(['tweet', 'retweet', 'reply']),
            };
        });
        return initialTweetTypes;
    });

    const updateTweetTypes = (username: string, newSelectedTweetTypes: Set<string>, newSoundTweetTypes: Set<string>) => {
        console.log('username', username);
        console.log('enabled sound types', newSoundTweetTypes);

        setTweetTypes((prevTweetTypes) => ({
            ...prevTweetTypes,
            [username]: {
                selectedTweetTypes: newSelectedTweetTypes,
                soundTweetTypes: newSoundTweetTypes,
            },
        }));

        localStorage.setItem(`selectedTweetTypes_${username}`, JSON.stringify([...newSelectedTweetTypes]));
        localStorage.setItem(`soundTweetTypes_${username}`, JSON.stringify([...newSoundTweetTypes]));
    };

    useEffect(() => {
        getSources().then((data) => {
            console.log('sources', data);
            //data = data.filter((source) => !COMMON_FEED.some((feed) => feed.username === source.username));

            console.log('filtered sources', data);
            setSources(data);
            setVisibleSources(data);

            let savedOrder = sourceOrder;
            if (sourceOrder.length === 0) {
                const storedOrder = localStorage.getItem('sourceOrder');
                savedOrder = storedOrder ? JSON.parse(storedOrder) : data.map((source) => source.username);
            }

            const updatedOrder = [...savedOrder];
            data.forEach((source) => {
                if (!updatedOrder.includes(source.username)) {
                    updatedOrder.push(source.username);
                }
            });

            setSourceOrder(updatedOrder);
            localStorage.setItem('sourceOrder', JSON.stringify(updatedOrder));

            setTweetTypes((prevTweetTypes) => {
                const newTweetTypes = { ...prevTweetTypes };

                data.forEach((source) => {
                    if (!newTweetTypes[source.username]) {
                        const selectedSaved = localStorage.getItem(`selectedTweetTypes_${source.username}`);
                        const soundSaved = localStorage.getItem(`soundTweetTypes_${source.username}`);

                        newTweetTypes[source.username] = {
                            selectedTweetTypes: selectedSaved ? new Set(JSON.parse(selectedSaved)) : new Set(['tweet', 'retweet', 'reply']),
                            soundTweetTypes: soundSaved ? new Set(JSON.parse(soundSaved)) : new Set(['tweet', 'retweet', 'reply']),
                        };
                    }
                });

                return newTweetTypes;
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReorder = (draggedUsername: string, hoveredUsername: string) => {
        const draggedIndex = sourceOrder.indexOf(draggedUsername);
        const hoveredIndex = sourceOrder.indexOf(hoveredUsername);

        if (draggedIndex !== -1 && hoveredIndex !== -1) {
            const newOrder = [...sourceOrder];
            newOrder.splice(draggedIndex, 1);
            newOrder.splice(hoveredIndex, 0, draggedUsername);
            setSourceOrder(newOrder);

            localStorage.setItem('sourceOrder', JSON.stringify(newOrder));
        }
    };

    const handleToggleSource = (source: Source, checked: boolean) => {
        if (checked) {
            setVisibleSources((prev) => [...prev, source]);
        } else {
            setVisibleSources((prev) => prev.filter((s) => s.username !== source.username));
        }
    };

    const visibleGeneralPosts = (checked: boolean) => {
        setGeneralPostsVisible(checked);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='container'>
                <section className={`header ${locationWithoutHeader ? 'token-create-header' : ''}`}>
                    <Navbar />
                    {!locationWithoutHeader && (
                        <div className='inputs'>
                            <div className='dyn-inputs'>
                                <label className={`custom-checkbox ${isGeneralPostsVisible ? 'checked' : ''}`}>
                                    <span className='label-text'>Everyone</span>
                                    <input type='checkbox' checked={isGeneralPostsVisible} onChange={(e) => visibleGeneralPosts(e.target.checked)} />
                                    <span className='custom-checkbox-box'>
                                        <span className='checkmark'>✔</span>
                                        <span className='cross'>✖</span>
                                    </span>
                                </label>
                            </div>
                            {sourceOrder.map((username, index) => {
                                const source = sources.find((s) => s.username === username);
                                return (
                                    source && (
                                        <Header
                                            key={index}
                                            source={source}
                                            onToggle={handleToggleSource}
                                            isChecked={visibleSources.some((s) => s.username === source.username)}
                                            sourceOrder={sourceOrder}
                                            handleReorder={handleReorder}
                                        />
                                    )
                                );
                            })}
                        </div>
                    )}
                </section>
                <section className='main-content'>
                    <Routes>
                        <Route
                            path='/'
                            element={
                                <MonitorFeed
                                    sources={visibleSources}
                                    setSources={setSources}
                                    isGeneralPostsVisible={isGeneralPostsVisible}
                                    tweetTypes={tweetTypes}
                                    updateTweetTypes={updateTweetTypes}
                                    solInitialBuyThunder={solInitialBuyThunder}
                                    autoSellDelayThunder={autoSellDelayThunder}
                                    autoSellProfitThunder={autoSellProfitThunder}
                                    buyPrio={buyPrio}
                                    buyJito={buyJito}
                                    sourceOrder={sourceOrder}
                                    handleReorder={handleReorder}
                                />
                            }
                        />
                        <Route
                            path='TokenCreate'
                            element={
                                <TokenCreate
                                    solInitialBuyArrow={solInitialBuyArrow}
                                    setSolInitialBuyArrow={setSolInitialBuyArrow}
                                    autoSellDelayArrow={autoSellDelayArrow}
                                    autoSellProfitArrow={autoSellProfitArrow}
                                    autoSellDelayCreation={autoSellDelayCreation}
                                    autoSellProfitCreation={autoSellProfitCreation}
                                    buyJitoTip={buyJito}
                                    buyPrio={buyPrio}
                                    ethPrivateKey={ethPrivateKey}
                                    solidityReleases={solidityReleases}
                                    availableNetworks={availableNetworks}
                                />
                            }
                        />

                        <Route path='Result' element={<Result sellJito={sellJito} sellPrio={sellPrio} />} />
                        <Route
                            path='Settings'
                            element={
                                <Settings
                                    solInitialBuyThunder={solInitialBuyThunder}
                                    setSolInitialBuyThunder={setSolInitialBuyThunder}
                                    solInitialBuyArrow={solInitialBuyArrow}
                                    setSolInitialBuyArrow={setSolInitialBuyArrow}
                                    solInitialBuyCopy={solInitialBuyCopy}
                                    setSolInitialBuyCopy={setSolInitialBuyCopy}
                                    solBuyPrio={buyPrio}
                                    setSolBuyPrio={setBuyPrio}
                                    solSellPrio={sellPrio}
                                    setSolSellPrio={setSellPrio}
                                    solBuyJito={buyJito}
                                    setSolBuyJito={setBuyJito}
                                    solSellJito={sellJito}
                                    setSolSellJito={setSellJito}
                                    autoSellDelayThunder={autoSellDelayThunder}
                                    setAutoSellDelayThunder={setAutoSellDelayThunder}
                                    autoSellProfitThunder={autoSellProfitThunder}
                                    setAutoSellProfitThunder={setAutoSellProfitThunder}
                                    autoSellDelayArrow={autoSellDelayArrow}
                                    setAutoSellDelayArrow={setAutoSellDelayArrow}
                                    autoSellProfitArrow={autoSellProfitArrow}
                                    setAutoSellProfitArrow={setAutoSellProfitArrow}
                                    autoSellDelayCreation={autoSellDelayCreation}
                                    setAutoSellDelayCopy={setAutoSellDelayCopy}
                                    autoSellProfitCreation={autoSellProfitCreation}
                                    setAutoSellProfitCopy={setAutoSellProfitCopy}
                                    ethPrivateKey={ethPrivateKey}
                                    setEthPrivateKey={setEthPrivateKey}
                                    solanaPrivateKey={solanaPrivateKey}
                                    setSolanaPrivateKey={setSolanaPrivateKey}
                                />
                            }
                        />
                        <Route
                            path='ErcTokenCreate'
                            element={<ErcTokenCreate ethPrivateKey={ethPrivateKey} solidityReleases={solidityReleases} availableNetworks={availableNetworks} />}
                        />
                    </Routes>
                </section>
                <section className='toast-cont'>
                    <ToastContainer position='bottom-right' theme='dark' />
                </section>
            </div>
        </DndProvider>
    );
}

export default App;
